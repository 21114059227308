.mainAbout{
    margin-top: -100px;
}
.about {
    /* margin-top: 60px; */
    background-image: url('../../component/images/aboutImage.jpeg');
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.about .title {
    height: 100vh;
    font-size: 60px;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;

}

/* About story section style  */

.aboutStory {
    margin-top: 60px;
    background: #D4F5E0;
}

.aboutStory .head .name {
    padding: 5px;
    padding-top: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #000000;
}

.aboutStory .head .name span {
    color: #00E567;
}

.aboutStory .aboutMain {
    margin-top: 50px;
}

.aboutStory .aboutMain .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.03em;
    color: #005742;

}

.aboutStory .aboutMain .sortDesc {
    max-width: 456px;
    height: 175px;
    margin-bottom: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.07em;
    color: #000000;

}

.aboutStory .aboutMain .fullDesc {
    margin-top: -50px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.07em;
    color: #000000;

}

/* just story style section  */
.storys {
    margin-top: 50px;
    margin-bottom: 30px;
}

.storys .heading {
    font-size: 18px;
    padding-top: 20px;
}

.storys .top {
    width: 90px;
}

.storys .bottom {
    width: 40px;
}

.storys .title {
    font-size: 60px;
}

.storys .text {
    margin-top: 60px;
    font-size: 20px;
    padding: 5px;
}

.storys .description {
    margin-top: 50px;
    font-size: 20px;
    padding: 5px;
}

/* Features section style  */
.feature {
    margin-top: 50px;
    /* margin-bottom: 100px; */
    background: #D4F5E0;
}
.feature .aboutStory .head{
    padding-top: 50px;
}
.feature .allFeatures{
    padding-top: 30px;
    padding-bottom: 50px;
}

.details {
    /* background-color: gray; */
    text-align: center;
}

.details .heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 24px;
    /* identical to box height, or 75% */

    letter-spacing: 0.03em;

    color: #000000;
}

.glance {
    text-align: center;
}

.glance .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #000000;
}

.glance .points {
    padding-top: 40px;
}

.glance .point {
    margin: auto;
    margin-bottom: 20px;
    width: 70%;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #000000;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}
.glance .point1 {
    margin: auto;
    margin-bottom: 20px;
    width: 70%;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #000000;
    background: #FFE9D4;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.glance .point .icon,
.glance .point1 .icon {
    margin-right: 10px;
    font-size: 25px;
    background: #00E667;
    border-radius: 50%;
}

.glance hr {
    width: 48%;
    margin-left: 27%;
}

/* About people says section sstyle */
.aboutPeoples {
    margin-bottom: 50px;
}

.aboutPeople {
    margin-top: 60px;
    background-image: url('../../component/images/aboutImage.jpeg');
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.aboutPeoples .aboutColor {
    margin-top: 70px;
    color: white;
}

.aboutHeading {
    text-align: center;
    color: white;
}

.aboutHeading .heading {
    padding-top: 70px;
    font-size: 20px;
}

.aboutHeading .title {
    font-size: 50px;
    padding: 5px;
    font-weight: bold;
}

.aboutColor .aboutCol {
    padding: 20px;
}

.aboutColor .aboutCol p {
    font-size: 20px;
}

.aboutColor .aboutCol h6 {
    margin-top: -10px;
    padding: 0;
}

/* Our happy Client section style  */
.happyClient {
    margin-top: 50px;
    padding-bottom: 90px;
    background-color: white;
}

.happyClientHeading {
    text-align: center;
    padding: 50px;
    font-size: 50px;
}

@media screen and(max-width:678px) {}