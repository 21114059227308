.contactBanner {
    margin-top: 50px;
    background-image: url('../../component/images/contact.png');
    height: 500px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.contactBanner .head {
    text-align: center;
    padding-top: 150px;
}

.contactBanner .head .name {
    font-family: 'Poppins';
    font-style: normal;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 48px;
    /* letter-spacing: 0.05em; */
    color: #00E667;
}

.contactInfo {
    height: 200px;
    width: 100%;
    margin-top: 150px;
    background: #F3FBF4;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

/* .infoName {
    height: 100px;
    width: 100%;
    padding: 30px;
} */

.contactInfo .infoName {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 3px;
}

.infoName .singleInfo {
    display: flex;
    justify-content: start;
    justify-items: start;
    align-items: center;
}

.contactInfoBox p {
    text-align: end;
    text-decoration: underline;
    text-underline-offset: 5px;
}

.singleInfo select {
    border: none;
    outline: none;
}

.contactButton button {
    margin-top: 30px;
    margin-left: 30px;
    padding: 5px;
    height: 62px;
    background-color: #00E667;
    color: white;
    text-transform: uppercase;
    border: none;
    border-radius: 10px;
}

/* contact page of frequnctly ask question style  */
.contactQA {
    padding-top: 10px;
    min-height: 700px;
    width: 100%;
    background-color: #FFE9D4;
    display: flex;
    place-content: center;
    place-items: center;
    ;
}

.contactQA .firstContents {
    border-right: 5px solid #A9F0C6;
    min-height: 400px;
    display: flex;
    place-content: center;
    place-items: center;

}

.contactQA .firstCol .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 50px;
    /* letter-spacing: 0.05em; */
    color: #000000;
}

.contactQA .firstCol .name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 50px;
    letter-spacing: 0.05em;
    color: #000000;
}

/* Where find  us section style  */
.whereFind {
    background-color: #D7FEE5;
    margin-bottom: 10px;
}

.whereFind .heading {
    padding-top: 100px;
}

.whereFind .heading .text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 50px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
}
.whereImage {
    text-align: center;
    margin-top: 50px;
}
.whereImage img{
    height: 250px;
    width: 380px;
    border-radius: 5px;
}
.whereContent {
    text-align: center;
    margin-top: 50px;
    display: flex;
    place-content: center;
    place-items: center;
}
.whereContent .allContent{
    min-height: 250px;
    width: 380px;
    font-family: 'Poppins';
    padding-bottom: 5px;
}
.whereContent .allContent .title{
    font-weight: bold;
}




@media screen and (max-width:678px) {

    .contactBanner {
        min-height: 100%;
    }

    .contactBanner .head {
        padding-top: 90px;
    }

    .infoName {
        width: 100%;
        padding: 0px;
    }

    .contactInfo {
        height: 100%;
        width: 70%;
        margin-top: 0px;
        background: #F3FBF4;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
    }

    .contactInfo .infoName {
        width: 100%;
        height: 500px;
        display: flex;
        flex-direction: column;
    }

    .contactInfoBox p {
        display: none;
    }

    .contactButton button {
           margin-left: 15px;
           padding: 10px;
    }

    .contactQA .firstContents {
        border-right: 0px solid #A9F0C6;
        border-bottom: 5px solid #A9F0C6;
    }

    .secondCol {
        margin-top: 10px;
    }

    .whereImage img{
        height: 250px;
        width: 380px;
        border: 1px solid black;
    }
}