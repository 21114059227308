.blogBanner {
    background-image: url('../../component/images/homeBlog.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 820px;
    width: 100%;
    /* background: #919894; */
    border-radius: 8px;
    position: relative;
}


.blogContents .blogHeading {
    text-align: center;

}

.blogContents .blogHeading .name {
    padding-top: 100px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.07em;
    color: #000000;

}

.blogContents .blogHeading .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #00E567;

}

.blogContents .blogFooter {

    /* display: flex;
    place-items: center;
    place-content: center; */
}


.blogContents .blogDesc {

    display: flex;
    place-content: center;
    place-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 160px;

}

.blogContents .blogDesc .desc {
    width: 350px;
    height: 175px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.07em;
    color: #000000;

}

.blogBanner .blogButton {
    display: flex;
    place-content: center;
    place-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50px;
}

.blogBanner .blogButton button {
    width: 165px;
    height: 62px;
    background: #00E667;
    border-radius: 8px;
    border: none;
    color: white;
}

/* second Banner section style  */

.blogSecond {
    background-image: url('../../component/images/BlogBanner.jpg');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.secondBlogContent {
    height: 100%;
    display: flex;
    place-content: center;
    place-items: center;
}

.secondBlogContent .name {
    width: 520px;
    height: 104px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    text-align: center;
    line-height: 52px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #00E667;
}

.blogSecond .secondBlogButtons {
    text-align: center;
    margin-top: -150px;
}

.blogSecond .secondBlogButtons .button1 {
    width: 307px;
    height: 50.31px;
    margin-bottom: 10px;
    background: #00E667;
    border-radius: 8px;
    font-family: 'Poppins';
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    transition: 0.4s;

}

.blogSecond .secondBlogButtons .button1:hover {
    transform: translateY(-7px);
}

.blogSecond .secondBlogButtons .button2 {
    width: 307px;
    height: 50.31px;
    margin-left: 10px;
    margin-bottom: 10px;
    background: #00E667;
    border-radius: 8px;
    font-family: 'Poppins';
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    transition: 0.4s;
}

.blogSecond .secondBlogButtons .button2:hover {
    transform: translateY(-7px);
}

/* frequency ask question style section  */

.faq {
    margin-top: 50px;
}

.faq .head .text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 50px;
}

.faqContent {
    width: 60%;
    margin: auto;
    padding: 20px;
    background: rgba(169, 240, 198, 0.87);
    border-radius: 8px;
    margin-bottom: 50px;
}

.faqImage {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

.faqImage img {
    border-radius: 10px;
    height: 300px;
    width: 80%;
    margin-bottom: 10px;
}