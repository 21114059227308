
.main{
    margin-top: 0;
    width: 100%;
    height: 60px;
    background-color: black;
}
.navHeading{
    color: white;
    display: flex;
    justify-content: space-between;
}

.items{
    display: flex;
    justify-content: space-around;
}

.items p{
    margin-top: 15px;
    margin-right: 10px;
}

/* .mainNav{
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: 90px;
} */

.change {
    /* background-color: rgb(230, 225, 225); */
    background: linear-gradient(
      145.07deg,
      rgba(37, 220, 110, 0.6786) 7.84%,
      rgba(0, 156, 120, 0.78) 92.14%
    );
    color: black;
    border-radius: 10px;
}

.sticky{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    transform: .5s;
}

.navMenu{
    color: black;
    font-size: 18px;
    font-weight: bold;
}

@media screen and (max-width:678px) {
    Nav{
        margin-left: 5px;
    }
    .barNav{
        margin-left: 10px;
    }
    .barNav1{
        margin-right: 20px;
    }
}