.galleryBanner {
    background-image: url('../../component/images/galleryDummy.jpg');
    height: 600px;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.galleryBanner .name {
    font-size: 70px;
    color: white;
    font-weight: 400px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

/* Banner two style  */
.secondBanner {
    background-image: url('../../component/images/PicFlyer.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 700px;
    width: 100%;
    /* background: #D1DBDD; */
    border-radius: 8px;
}

.secondBanner .heading {
    display: flex;
    place-content: center;
    place-items: center;
    padding-top: 100px;
}

.secondBanner .secondBannerButtons{
    display: flex;
    place-content: center;
    place-items: center;
    padding-top: 50px;
}

.secondBanner .secondBannerButtons2 {
    display: flex;
    place-content: center;
    place-items: center;
    padding-top: 25px;
}

.secondBanner .secondContent {
    display: flex;
    place-content: center;
    place-items: center;
    margin-top: 150px;
}

.secondBanner .heading p {
    width: 536px;
    height: 106px;
    left: 452px;
    top: 370px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 53px;
    text-align: center;
    letter-spacing: 0.07em;
    color: #00E667;

}

.secondBanner .secondContent p {
    width: 391px;
    height: 70px;
    left: 525px;
    top: 676px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.07em;
    color: #00E667;
}
.secondBanner .secondBannerButtons .icon{
    color: white;
    background-color: #00E567;
}
.secondBanner .secondBannerButtons button {
    width: 165px;
    height: 67px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #00E567;
    border: none;
    border-radius: 8px;
}

.secondBanner .secondBannerButtons2 button {
    width: 306px;
    height: 67px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 25px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #00E567;
    border: none;
    border-radius: 8px;
}

.photoGallery {
    margin-top: 100px;
}

.photoGallery .image img {
    height: 250px;
    width: 350px;
}

.photoGallery .content {
    box-sizing: border-box;
    text-align: center;

}

.photoGallery .content .title {
    font-size: 35px;
    padding: 5px;
    font-weight: 400;
}

.photoGallery .content .description {
    font-size: 22px;
    padding: 10px;
    font-weight: 400;
}

/* just gallery images style  */
.justGallery{
    margin-top: 50px;
    margin-bottom: 50px;
}
.justGallery img{
    width: 100%;
    height: 100%;
}

/* all photos section style  */

.allPhotos {
    margin-top: 100px;
    background-color: black;
}

.allPhotos .heading {
    text-align: center;
    color: white;
    text-transform: uppercase
}

.allPhotos .heading .title {
    padding: 50px;
    font-size: 15px;
}

.allPhotos .heading .name {
    font-size: 45px;
    font-weight: 400;
    padding-bottom: 50px;
}

.allPhotos .galleryShow img {
    align-items: center;
    width: 100%;
    height: 500px;
    transition: .4s;
}

.allPhotos .galleryShow img:hover {
    transform: scale(.9);
    cursor: pointer;
}

.allPhotos .smallGallery {
    margin-top: 10px;
    padding-bottom: 20px;
}

.allPhotos .smallGallery .gallerySmall img {
    height: 250px;
    width: 100%;
}

/* gallery work place section style  */

.workPlace {
    padding-bottom: 30px;
}

.workPlace1 {
    background-image: url('../../component/images/galleryShow.jpg');
    min-height: 100vh;
    background-position: center;
    background-size: cover;
}

.workPlace2 {

    background-color: black;
    padding-bottom: 40px;
}

.workPlace3 {
    background-image: url('../../component/images/galleryShow4.jpeg');
    min-height: 100vh;
    background-position: center;
    background-size: cover;
}

.middleWork {
    margin-top: 50px;
    text-align: center;
    color: white;
}

.middleWork .head {
    font-size: 20px;
    padding-top: 20px;
    text-transform: uppercase;
}

.middleWork hr {
    width: 60px;
    margin-left: 45%;
    border: 1px solid white;
}

.middleWork .title {
    font-size: 50px;
    padding-top: 20px;
    text-transform: uppercase;
}

.middleWork .text {
    box-sizing: border-box;
    font-size: 20px;
    padding: 50px;
}

.middleWork .buttons .button1 {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 20px;
    border-top: 1px solid white;
    text-transform: uppercase;
}

.middleWork .buttons .button1:hover {
    transform: translateY(-5px);
    transition: .4s;
}

.middleWork .buttons .button2 {
    background-color: transparent;
    color: white;
    font-size: 20px;
    width: 300px;
    border: 1px solid white;
    text-transform: uppercase;
}

.middleWork .buttons .button2:hover {
    transform: translateY(-5px);
    transition: .4s;
}

.workSlider {
    margin-top: 10px;
}

.image-img {
    height: 400px;
    width: 100%;
}

.workPlace .workSmallGallery img {
    width: 100%;
}

/* Explore our video style section  */
.videoExplore {
    margin-top: 50px;
}

.videoExplore .heading {
    background-color: black;
    height: 300px;
    display: flex;
    place-content: center;
    place-items: center;
}

.videoExplore .heading p {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 396px;
    height: 138px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 63px;
    line-height: 74px;
    text-align: center;
    letter-spacing: 0.05em;
    color: white;

}

.exploreGallery {
    margin-top: 50px;
}

.exploreImage {
    background-image: url('../../component/images/gallery.jpeg');
    height: 450px;
    /* min-height: 100vh; */
    background-position: center;
    background-size: cover;
    margin-bottom: 20px;
    position: relative;
}

.exploreGallery .name {
    padding-top: 100px;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: white;
}

.exploreGallery .exploreButton {
    display: flex;
    justify-content: center;
    justify-items: center;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 40px;
}

.exploreGallery button {
    width: 126px;
    height: 73px;
    background-color: #00E567;
    color: white;
    text-transform: uppercase;
    border: none;
    border-radius: 8px;
}