.footer {
    height: 350px;
    width: 100%;
    background: #D7FEE5;
    /* margin-bottom: 30px; */
}

.footerContents {
    height: 100%;
    display: flex;
    place-content: center;
    place-items: center;
}

.footerContents .contents .socials {
    margin-left: 30%;
    display: flex;
}

.footerContents .contents .socials .icons {
    width: 62px;
    height: 62px;
    margin-right: 50px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    place-content: center;
    place-items: center;
}

.footerContents .contents .socials .icon {
    width: 57px;
    height: 57px;
}

.footerContents .footerText {
    margin-left: 10px;
    margin-top: 50px;
    display: flex;
}

.footerContents .footerText .location,
.footerContents .footerText .info {
    display: flex;
    justify-content: start;
    justify-items: start;
    align-items: start;
}

.footerContents .footerText .location img,
.footerContents .footerText .info img {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    background-color: none;
}
.footerContents .footerText .info{
    margin-bottom: 10px;
}

@media screen and (max-width:678px) {

    .footer {
        height: 100%;
        width: 100%;
        background: #D7FEE5;
    }
    .footerContents .contents .socials {
        margin-top: 10px;
        margin-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footerContents .contents .socials .icons {
        margin-top: 10px;
    }
}