* {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

.banner {
  margin-top: 50px;
  background: linear-gradient(
      145.07deg,
      rgba(37, 220, 110, 0.6786) 7.84%,
      rgba(0, 156, 120, 0.78) 92.14%
    ),
    url("../../component/images/homeBgClinic.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bannerHeading {
  padding-top: 150px;
  font-weight: bold;
  text-align: center;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  line-height: 94px;

  text-align: center;
  letter-spacing: 0.03em;

  color: #ffffff;
}

.bannerHeading p span {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 20px;
  font-size: 20px;
  text-transform: uppercase;
}

.bannerHeading h2 {
  margin-top: 50px;
  font-size: 40px;
  padding: 5px;
}

.bannerHeading hr {
  width: 100px;
  border: 1px solid white;
  text-align: center;
  margin-left: 46%;
}

.bannerButtons {
  text-align: center;
  margin-top: 80px;
  font-size: 30px;
  padding: 40px;
}

.bannerButtons button {
  text-transform: uppercase;
  color: white;
  padding: 10px;
  background-color: Transparent;
  border: none;
  transition: 0.4s;
}

.bannerButtons button:hover {
  transform: translateY(-7px);
}

.bannerButtons .button1 {
  width: 250px;
  height: 47px;
  background: #ffffff;
  box-shadow: 0px 0px 15px #005742;
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #00e667;
}

.bannerButtons .button2 {
  width: 190px;
  height: 53px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #ffffff;
  /* border-bottom: 1px solid white; */
  margin-left: 20px;
  text-decoration: underline;
  text-underline-offset: 4px;
}

/* .bannerButtons .button2 ul {
   padding-top: 20px;

} */

/* our quality section style  */
.ourQuality {
  /* margin-top: 20px; */
}

.ourQuality .head {
  margin-bottom: 70px;
}

.ourQuality .head .title {
  padding-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  letter-spacing: 0.03em;
  color: #000000;
}

.ourQuality .head span {
  color: #00e567;
}

.ourQuality .head .text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
  letter-spacing: 0.05em;
  color: #000000;
}

.ourQuality .headImage img {
  /* width: 375px; */
  height: 450px;
  width: 100%;
}

.therapy {
  padding-top: 120px;
  /* margin-left: 50px */
}

.therapy .title {
  width: 400px;
  text-transform: uppercase;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #000000;
}

.therapy .desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 35px;
  text-align: justify;
  letter-spacing: 0.07em;
  color: #000000;
}

.qualityColumns {
  margin-top: 50px;
}

.qualityColumns .qualitySelf {
  margin-bottom: 25px;
  min-height: 180px;
  background: #ffffff;
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 12px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  /* display: flex; */
}
.qualityColumns .qualitySelf .qualityImg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qualityColumns .qualitySelf img {
  width: 110px;
  height: 110px;
  /* margin-left: 40px; */
  margin-top: 23px;
  /* margin-bottom: 34px; */
  border-radius: 10px;
}

.qualityColumns .qualitySelf .qualityTitle {
  margin-top: 45px;
  margin-left: 24px;
}

.qualityColumns .qualitySelf .qualityTitle .name {
  /* margin-right: 40px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: justify;
  letter-spacing: 0.05em;
  color: #000000;
}

.qualityColumns .qualitySelf .qualityTitle .title {
  /* height: 72px;
    margin-right: 40px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  letter-spacing: 0.05em;
  color: #000000;
}

/* About Co-founder style */

.founder {
  margin-top: 10px;
  background-color: rgb(243, 241, 241);
}

.founderAbout {
  padding-top: 100px;
  padding-bottom: 80px;
}

.fonderImage {
  overflow: hidden;
}

.fonderImage img {
  height: 100%;
  width: 100%;
}

.founderAbout .myself {
  height: 150px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding-left: 20px;
  margin-left: -90px;
  position: relative;
}

.founderAbout .myself hr {
  border: none;
  border-bottom: 3px solid black;
  /* padding-top: 40px; */
  width: 100px;
  position: absolute;
  top: 20px;
}

.founderAbout .myself h1 {
  padding-top: 60px;
}

.describe p {
  padding-top: 10px;
}

.describe .aboutMySelf {
  padding-top: 30px;
}

.describe span hr {
  width: 60px;
}

.founderAbout button {
  margin-top: 30px;
  height: 45px;
  width: 250px;
  border-radius: 20px;
  background-color: black;
  color: white;
  font-size: 17px;
  margin-bottom: 0;
}

.describe .downArrow {
  margin-top: 0;
  font-size: 80px;
}

/* Our team section style */

.teamHeading {
  margin-top: 0;
  text-align: center;
  font-size: 60px;
  font-weight: 500;
  padding: 20px;
}

.teamHeading hr {
  width: 80px;
  margin-left: 46%;
}

.aboutTeam {
  padding-top: 20px;
  font-size: 22px;
}

.teamGalery {
  margin-top: 100px;
}

.teamGalery img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s ease-in;
}

.teamGalery img:hover {
  transform: translateY(-10px);
}

.teamGalery,
.benefit .title p {
  margin-top: 25px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.benefitImage img {
  height: 100px;
  width: 100px;
  border-radius: 10px;
}

.teamGalery .title p small {
  text-align: center;
  font-size: 15px;
  font-weight: normal;
}

.teamButton {
  text-align: center;
  margin-top: 40px;
  padding: 5px;
}

.teamButton button {
  border-radius: 20px;
  background-color: black;
  color: white;
  font-size: 18px;
  font-weight: bold;
}

/* Treatment section style  */

.ourTreatment {
  margin-top: 60px;
}

.treatment {
  margin-top: 50px;
}

.treatmentImages {
  text-align: center;
}

.treatmentImage {
  margin-top: 10px;
}

.treatmentbutton {
  text-align: center;
}

.treatmentbutton button {
  margin-top: 20px;
  border: none;
  background-color: white;
  font-size: 30px;
  font-weight: 400;
  border-top: 1px solid black;
  transition: 0.4s;
}

.treatmentbutton button:hover {
  transform: translateY(-7px);
}

/* benefit section style  */
.benefit {
  margin-top: 20px;
}

.benefit {
  background-image: url("../../component/images/benifit.jpg");
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

/* Recoverd section style  */
.recover {
  margin-top: 100px;
}

.recover .head {
  text-align: center;
}

.recover .head {
  margin-bottom: 30px;
  display: flex;
  place-content: center;
  place-items: center;
}

.recover .head .title {
  width: 390px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  /* line-height: 63px; */
  letter-spacing: 0.07em;
  color: #000000;
}

.recover .text {
  margin-bottom: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #000000;
}

.recoverd {
  background-image: url("../../component/images/recoverd.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
  min-height: 820px;
  width: 100%;
  /* background: #919894; */
  border-radius: 8px;
  text-align: center;
  padding-top: 10px;
  position: relative;
}

.recoverd .heading {
  margin-top: 100px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
}

.recoverd .heading span {
  color: #00e567;
  text-transform: uppercase;
}

.recoverd .content {
  display: flex;
  place-content: center;
  place-items: center;
}

.recoverd .content .text {
  width: 273px;
}

.recoverd .recoverdButton {
  display: flex;
  place-content: center;
  place-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
}

.recoverd .recoverdButton button {
  width: 155px;
  height: 50px;
  color: white;
  background: #00e667;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  border: none;
}

.benefitHeading {
  margin-top: 0;
  text-align: center;
  font-size: 60px;
  font-weight: 500;
  padding-top: 100px;
  color: white;
}

.benefitHeading hr {
  width: 80px;
  margin-left: 46%;
}

.benefit .content {
  margin-top: 50px;
}

.benefitImage {
  text-align: center;
}

/* feature section style  */
.features {
  margin-top: 90px;
  margin-left: -90px;
}

.featureShadow {
  height: 250px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background-color: white;
}

.featureText {
  padding: 40px 70px;
}

.featureIcon {
  color: black;
  font-size: 80px;
}

.featureButton {
  margin-top: 25px;
}

.featureButton button {
  height: 50px;
  width: 100px;
  background-color: white;
  border-radius: 20px;
  transition: 0.4s;
}

.featureButton button:hover {
  background-color: gray;
  color: white;
  font-weight: bold;
  transform: translateY(-5px);
}

/* our success section style  */

.success {
  margin-top: 100px;
}

.successText h1 {
  font-size: 50px;
  padding: 10px;
  margin-top: 20px;
}

.successText p {
  font-size: 30px;
  padding: 30px;
  margin-top: 20px;
}

.successImage {
  overflow: hidden;
}

.successImage img {
  height: 100%;
  width: 100%;
}

.success .buttons {
  text-align: center;
}

.success .buttons .button1 {
  font-size: 20px;
  margin: 10px;
  padding: 10px;
  border: none;
  background-color: white;
  border-top: 1px solid black;
  transition: 0.4s;
}

.success .buttons .button1:hover {
  transform: translateY(-7px);
}

.success .buttons .button2 {
  font-size: 22px;
  margin-bottom: 10px;
  padding: 15px;
  width: 200px;
  border: 1px solid black;
  transition: 0.4s;
}

.success .buttons .button2:hover {
  transform: translateY(-7px);
}

.anotherHeading {
  text-align: center;
  font-size: 25px;
  padding: 30px;
}

.anotherInfo {
  font-size: 20px;
  padding: 5px;
}

.anotherInfo p {
  padding: 15px;
  text-align: center;
}

.anotherSelf {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 5px;
}

.anotherImage {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-right: 15px;
}

.anotherLast {
  /* margin-top: 60px; */
}

.anotherLast .img1 {
  height: 330px;
  width: 90%;
  transform: perspective(600px) rotateY(10deg) translateZ(55px);
}

.lastbg1 {
  background-image: url("../../component/images/rotate1.png");
  height: 330px;
  width: 40%;
  transform: perspective(200px) rotateY(10deg) translate3d(0px, 20px, 20px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.lastbg1 .lastbgContent,
.lastbg2 .lastbgContent {
  height: 100%;
  display: flex;
  place-content: center;
  place-items: center;
}

.lastbg1 .lastbgContent .name,
.lastbg2 .lastbgContent .name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #000000;
}

.lastbg1 .lastbgContent .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 35px;
  /* text-align: justify; */
  color: #000000;
}

.lastbg2 .lastbgContent .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 35px;
  text-align: center;
  color: #000000;
}

.lastbg1 .lastbgContent button,
.lastbg2 .lastbgContent button {
  width: 165px;
  height: 62px;
  background: #00e667;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  border: none;
  color: white;
}

.lastbg2 {
  background-image: url("../../component/images/rotate2.png");
  height: 330px;
  width: 40%;
  transform: perspective(200px) rotateY(-10deg) translate3d(-45px, 25px, 10px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.anotherLast .img2 {
  height: 330px;
  width: 80%;
  margin-top: 120px;
  transform: perspective(100px) rotateY(-10deg) translateZ(20px);
}

/* People says or people review */

.peopleSay {
  margin-top: 100px;
}

.peopleMain {
  /* padding: 80px; */
}

.peopleMain .heading .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  /* line-height: 35px; */
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
}

.peopleMain .heading .name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
  margin-top: -20px;
}

.peopleMain .peoples {
  margin-top: 60px;
}

.anotherInfo {
  min-height: 300px;
  margin-bottom: 30px;
  padding: 20px;
  font-size: 20px;
  position: relative;
}

.peopleMain .people,
.aboutCol {
  min-height: 200px;
  margin-bottom: 30px;
  padding: 20px;
  font-size: 16px;
  position: relative;
}
.peopleMain .people .textPeople {
  margin-bottom: 50px;
}
.infoPeople .name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
  line-height: normal;
  letter-spacing: 0.03em;
  color: #00e667;
}

.infoPeople .title {
  font-family: "Poppins";
  font-style: normal;
  font-size: 16px;
}

.peopleSay .title {
  font-size: 18px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.peopleSay .text {
  font-size: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.peopleReview .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 35px;
  text-align: justify;
  color: #000000;
}

.peopleReview .reviewBox {
  min-height: 470px;
  margin-bottom: 20px;
  background: rgba(169, 240, 198, 0.87);
  /* background: #FFE9D4; */
}

.peopleReview .clientSelf {
  border-radius: 8px;
  padding-top: 50px;
  padding-left: 40px;
  padding-right: 3px;
  display: flex;
}

.clientSelf .clientImg img {
  width: 90px;
  height: 90px;
  margin-right: 24px;
  border-radius: 50%;
  background: #00e667;
}

.clientSelf .clientTitle .name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #00e667;
  margin-top: 10px;
}

.clientSelf .clientTitle .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: black;
  margin-top: -45px;
}

.reviewBox .clientDesc p {
  margin: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  text-align: justify;
  color: #000000;
}

.peopleReview .clientMore {
  text-align: center;
  padding: 100px;
}

.peopleReview .clientMore button {
  width: 165px;
  height: 62px;
  border: none;
  color: white;
  background: #00e667;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

/* blog section style  */
.blog {
  padding-bottom: 30px;
}

.blogImage {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.parent {
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s;
}

.blogImage {
  background-image: url("../../component/images/feature.jpg");
  height: 480px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1.3);
  transition: all 0.5s;
}

.parent:hover,
.blogImage:hover {
  transform: scale(1);
  box-shadow: 0px 6px 0px black;
}

.blogImage .contents {
  text-align: end;
  color: white;
  padding-right: 100px;
  height: 100%;
  box-shadow: inset 0 0 0 100vw rgba(0, 0, 0, 0.5);
  transition: 0.4s;
}

.blogImage .contents:hover {
  box-shadow: inset 0 0 0 100vw rgba(0, 0, 0, 0.2);
}

.blogImage .contents .name {
  padding-top: 130px;
  font-size: 17px;
  text-transform: uppercase;
}

.blogImage .contents .title {
  font-size: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-transform: uppercase;
}

.blogImage .contents button {
  color: white;
  font-size: 18px;
  padding: 10px;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  border-top: 2px solid white;
}

.blogContent {
  padding-top: 20%;
}

.blogContent hr {
  width: 80px;
  color: black;
  border-top: 2px solid black;
  margin-left: 50px;
}

.blogContent .text {
  padding: 50px;
}

/* what we serve section style  */
.weServe {
  margin-top: 30px;
}

.weServe .heading .name {
  width: 142px;
  height: 24px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #005742;
}

.weServe .heading .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #000000;
}

.weServe .heading .title span {
  color: #18e273;
}

.weServe .heading .text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #000000;
}

.conditionRows {
  margin-top: 50px;
}

.conditionRows .conditionBox {
  text-transform: uppercase;
  margin-top: 30px;
  margin-right: 30px;
  display: flex;
  place-items: center;
  place-content: center;
}
.conditionRows .conditionBox .text:hover,
.conditionRows .conditionBox .text1:hover {
  background-color: gray;
  color: white;
}

.conditionRows .conditionBox .text {
  width: 241px;
  height: 65px;
  text-align: center;
  background: #d7fee5;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  place-items: center;
  place-content: center;
}

.conditionRows .conditionBox .text1 {
  width: 241px;
  height: 65px;
  text-align: center;
  background: #ffe9d4;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  place-items: center;
  place-content: center;
}

.serveContent {
  margin-top: 50px;
  /* display: grid;
    grid-template-columns: 1.5fr 1fr; */
}

.serveContent .text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: justify;
  letter-spacing: 0.05em;
  color: #000000;
}

.contentServe {
  align-items: center;
}

.contentServe p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.03em;
  color: #000000;
}

.contentServe p span {
  font-size: 20px;
  color: #3adf7c;
}

.serveInput {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
}

.serveInput input {
  width: 680px;
  height: 58px;
  position: relative;
}

.serveInput button {
  width: 138px;
  height: 58px;
  margin-right: 50px;
  background: #00e667;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 8px;
  position: absolute fixed;
}

.searchText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  /* or 130% */

  letter-spacing: 0.03em;

  color: #000000;
}

/* .serveImage {
    background: url('../../component/images/another.jpg'), #D9D9D9;
    height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
} */
.weImage {
  height: 400px;
  width: 100%;
  overflow: hidden;
}

/* Contact section style  */
.contactHome {
  background-color: rgb(251, 249, 249);
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 20px;
}
.contactHome .addressTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins";
  font-style: normal;
  text-transform: uppercase;
  text-decoration: underline;
  text-underline-offset: 5px;
}
.contactHome .contactAddress {
  height: 300px;
  display: flex;
  place-content: center;
  place-items: center;
}

.contactHome .contactAddress button {
  width: 150px;
  height: 48px;
  margin-top: 20px;
  background: #00e667;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.contactHome .contactMap iframe {
  height: 300px;
  width: 100%;
}

@media screen and (max-width: 678px) {
  .therapy {
    padding-top: 30px;
    margin-left: 0px;
  }

  .therapy .title {
    width: 380px;
  }

  .qualityColumns .qualitySelf {
    margin-bottom: 25px;
    min-height: 252px;
    background: #ffffff;
    border: 3px solid #ffffff;
    box-shadow: 0px 0px 12px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    flex-direction: column;
  }

  .founderAbout .myself {
    margin-left: 0px;
  }

  .conditionRows .conditionBox {
    margin-top: 50px;
    margin-right: 0px;
    display: flex;
    place-items: center;
    place-content: center;
  }

  .contentPoints {
    display: flex;
    place-items: center;
    place-content: center;
  }

  .features {
    margin-left: 0px;
  }

  .featureText {
    padding: 5px 70px;
  }
}
