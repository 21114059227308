.serviceTeam {
    margin-top: 5px;
    padding-bottom: 20px;
}

.serviceTeam .heading .title {
    text-align: center;
    font-size: 55px;
    padding: 30px;
    font-weight: 400;
}

.serviceTeam .heading hr {
    width: 70px;
    margin-left: 45%;
}

.serviceTeam .heading .text {
    font-size: 20px;
    padding-top: 20px;
}

.serviceTreatment {
    margin-top: 40px;
}

.serviceTreatment .column {
    color: white;
    background-color: gray;
    border: 1px solid white;
    font-size: 20px;
    text-align: center;
    padding: 10px;
}

.serviceTreatment .column:hover {
    background-color: rgb(33, 33, 33);
    transition: .5s;
}

/* conditions section style  */
.conditions {
    margin-top: 50px;
}

.conditions .heading .title {
    text-align: center;
    font-size: 55px;
    padding: 30px;
    font-weight: 400;
}

.conditions .heading hr {
    width: 70px;
    margin-left: 45%;
}

.conditions .heading .text {
    font-size: 20px;
    padding-top: 20px;
}

.conditionPoints {
    margin-top: 100px;
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
    text-align: center;
}

/* Time shedule section style  */
.timeShedule {
    margin-top: 100px;
    background-image: url('../../component/images/another4.jpg');
    min-height: 100vh;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.timeShedule .heading {
    text-align: center;
}

.timeShedule .title {
    color: white;
    font-size: 50px;
    font-weight: 500;
    padding-top: 100px;
}

.timeShedule button {
    background-color: white;
    border: none;
    text-align: center;
    font-size: 20px;
    padding: 20px;
    border-radius: 20px;
    margin-top: 30px;
}

.timeShedule button:hover {
    transform: translateY(-6px);
    transition: 0.4s;
}

.timeShedule .timingSection {
    background-color: rgb(9, 5, 5);
    color: white;
    text-align: center;
    height: 250px;
    margin-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.timingSection .text {
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 7px;
}

.timingSection .time {
    font-size: 45px;
}

.decoration {
    text-decoration: none;
    color: white;
}

/* our package section style  */
.packages {
    margin-top: 30px;
    margin-bottom: 80px;
}

.packages .heading {
    text-align: center;
}

.packages .title {
    font-size: 25px;
    padding-top: 20px;
    font-weight: bold;
}

.packages .name {
    font-size: 50px;
}

.packages .package {
    margin-top: 80px;
}

.package .priceBox {
    min-height:400px;
    background: #D7FEE5;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 30px;
}

.package .priceBox1 {
    background: #FFE9D4;
}

.package .priceBox {
    overflow: hidden;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
}

.package .priceBox .sticker {
    background-color:
        #00E567;
    color: white;
    width: 250px;
    font-size: 15px;
    transform: rotate(55deg);
    position: absolute;
    right: -90px;
    top: 40px;

}

.package .shadow {
    /* background-color: white;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    height: 500px;
    margin-top: -30px; */
}

.package .priceBox h1 {
    font-size: 30px;
}

.package .priceBox p {
    font-size: 17px;
}

.package .priceBox .prices {
    display: flex;
    justify-content: center;
    padding: 30px;
}

.package .priceBox .prices .amount {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 69px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #00E667;

}

.package .priceBox .prices p:nth-child(even) {
    font-size: 50px;
    font-weight: 700;
}

.package .priceBox .prices p:nth-child(1) {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 30px;
    font-weight: 500;
}

.package .priceBox .priceName {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 46px;
    /* or 284% */

    text-align: center;
    letter-spacing: 0.03em;

    color: #000000;
}

.package .priceBox .priceName .icon {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 46px;
    color: #00E567;
}

.package .priceBox button {
    width: 133px;
    height: 45px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.03em;
    color: #00E567;
    background: #FFE9D4;
    border: none;
    border-radius: 8px;
}

/* why choose us section style  */

.chooseUs {
    background: rgba(201, 242, 216, 0.8);
    padding-bottom: 30px;
}

.chooseUs .heading {
    padding-top: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    /* line-height: 35px; */
    text-align: center;
    letter-spacing: 0.03em;
    color: #000000;

}

.chooseUs .heading p {
    margin-top: 30px;
    margin-bottom: 70px;


}

.chooseBox {
    width: 307px;
    height: 261px;
    margin-left: 20px;
    margin-bottom: 55px;
    background: #FFE9D4;
    box-shadow: -1px 2px 15px rgba(0, 0, 0, 0.15), inset 7px 7px 8px #E4EAE8;
    border-radius: 8px;
}

.chooseImage {
    text-align: center;
}

.chooseImage {
    display: flex;
    place-content: center;
    place-items: center;
}

.chooseImage .imageDiv {
    box-sizing: border-box;
    margin-top: 24px;
    width: 62px;
    height: 62px;
    background: #FFFFFF;
    ;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    display: flex;
    place-content: center;
    place-items: center;
    align-items: center;
}

.chooseImage .imageDiv img {
    width: 42px;
    height: 26px;
}

.chooseUs .chooseContent .title p {
    width: 227px;
    height: 24px;
    margin-top: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #005742;
}

.chooseUs .chooseContent .text,
.chooseUs .chooseContent .title {
    display: flex;
    justify-content: center;
}

.chooseUs .chooseContent .text p {
    width: 214px;
    height: 66px;
    margin-left: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #000000;
}

.chooseUs .chooseButton {
    text-align: center;
    padding-bottom: 30px;
}

.chooseUs .chooseButton button {
    width: 155px;
    height: 50px;
    background: #00E567;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.09em;
    color: #FFFFFF;
}

.timeSheadule {
    margin-top: 100px;
    background-image: url('../../component/images/timeBg.png');
    min-height: 484px;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.timeSheadule {
    /* display: flex;
    place-content: center;
    place-items: center;
    position: relative; */
}

.timeSheadule .content p {
    padding-top: 50px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 80px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #00E667;
}

.sheduleButton{
    display: flex;
    place-content: center;
    place-items: center;
}
.timeSheadule button {
    width: 265px;
    height: 62px;
    color: white;
    text-transform: uppercase;
    /* position: absolute;
    bottom: 190px; */
    background: #00E667;
    box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.25);
    border-radius: 185px;
    border: none;
}

.timeSheadule .text {
    margin-top: 20px;
    text-transform: uppercase;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.5em;
    color: #00B38A;
    /* position: absolute;
    bottom: 130px; */
}

.timeSheadule .time {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #00E667;
    /* position: absolute;
    bottom: 100px; */
}

@media screen and (max-width:678px) {
    .conditionPoints {
        display: flex;
        flex-direction: column;
    }
}