.appointment {
  margin-top: 50px;
  margin-bottom: 30px;
  background: #f3fbf4;
}

.appointment .head p {
  padding-top: 60px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #00e667;
}

.appointment .appointmentForm {
  margin-top: 70px;
  padding-bottom: 30px;
}
.appointment .appointmentForm .checkPatient {
  width: 30%;
  display: flex;
  justify-content: space-between;
}

.appointment .appointmentForm .checkPatient .checkInput {
  width: 150px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding-top: 12px;
  padding-left: 20px;
}
.appointment .checkPatient .checkInput label {
  margin-left: 10px;
}
.appointment .field span{
  color: red;
}
.appointment .appointmentForm .field {
  width: 50%;
  margin-top: 25px;
}
.appointment .appointmentForm .field label {
  margin-left: 8px;
}
.appointment .appointmentForm .field input{
  width: 100%;
  height: 50px;
  padding-left: 20px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.appointment .appointmentForm .field .messageField {
  width: 100%;
  height: 150px;
  padding-left: 20px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.appointment .appointmentForm .field p {
  margin: 0;
  padding: 0;
  font-weight: 500;
  margin-left: 8px;
}
.appointment .categoryTime {
  margin-top: 50px;
  /* text-align: center; */
}
.appointment .categoryTime .appointmentInfo,
.infoImage {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}
.appointment .categoryTime .appointmentInfo .infoImage {
  width: 60%;
}
.appointment .categoryTime .appointmentInfo .infoImage p {
  margin-top: 15px;
}
.appointment .categoryTime .appointmentInfo select,
.appointment .categoryTime .appointmentInfo .datePicker {
  border: none;
  outline: none;
  border-bottom: 1px solid black;
  background-color: transparent;
  cursor: pointer;
}
.appointment .categoryTime .appointmentInfo .datePicker {
  /* margin-left: 120px; */
  width: 155px;
}
.appointment .categoryTime .appointmentInfo .dateArrow {
  position: relative;
}
.appointment .categoryTime .appointmentInfo span {
  font-size: 18px;
  font-weight: 900;
  position: absolute;
  right: 0px;
  bottom: 3px;
}
.appointment .appointButton {
  text-align: start;
  margin-top: 30px;
  padding-bottom: 20px;
}
.appointment .appointButton button {
  width: 185px;
  height: 52px;
  border: none;
  color: white;
  background: #00e667;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  letter-spacing: 0.03em;
  font-weight: 400;
  transition: 0.2s;
}
.appointment .appointButton button:hover {
  background: #30eb84;
  font-weight: 500;
}

@media screen and (max-width: 678px) {
  .appointment .appointmentForm .checkPatient {
    width: 80%;
    display: flex;
    justify-content: space-between;
  }

  .appointment .appointmentForm .checkPatient .checkInput {
    width: 130px;
    height: 50px;
  }

  .appointment .appointmentForm .field {
    width: 100%;
    margin-top: 25px;
  }
}
